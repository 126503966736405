.blog-filter {
  &__container {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    border: none;
    border-radius: 0.25rem;
    color: rgba($color-black, 0.85);
    background-color: $color-white;
    box-shadow: 0 1px 3px 0 rgba($color-black, 0.15),
      0 0 2px 0 rgba($color-black, 0.15);
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    > * + * {
      border-top: 1px solid rgba($color-black, 0.15);
    }
  }

  &__search {
    &__container {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__content {
      width: 100%;
      padding: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__loading {
      width: 100%;
      padding: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  &__category {
    &__container {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &__content {
      width: 100%;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;

      > * + * {
        margin-top: 8px;
      }
    }

    &__title {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      color: rgba($color-black, 0.85);
    }

    &__loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &__list {
      width: 100%;
      display: flex;
      flex-direction: column;

      &-item {
        width: 100%;
        display: flex;

        &.ellipsis {
          max-width: 230px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
        }

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  &__last-posts {
    &__container {
      width: 100%;
      display: flex;
      justify-content: center;

      min-height: 100px;
    }

    &__content {
      width: 100%;
      padding: 1.5rem;
      display: flex;
      align-items: center;
      flex-direction: column;

      > * + * {
        margin-top: 8px;
      }
    }

    &__title {
      width: 100%;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      color: rgba($color-black, 0.85);
    }

    &__list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .last-posts__list-item {
        &__title {
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;

          &.ellipsis {
            max-width: 230px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
          }

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }

        &__excerpt {
          font-size: 12px;
        }

        &__date {
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: $color-grey;

          > * + * {
            margin-left: 4px;
          }
        }

        &__category {
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: $color-grey;

          > .label:hover {
            cursor: pointer;
            text-decoration: underline;
          }

          > * + * {
            margin-left: 4px;
          }
        }

        .row {
          width: 100%;
          display: flex;
          align-items: center;

          > * + * {
            margin-left: 8px;
          }
        }

        > * + * {
          margin-top: 4px;
        }
      }

      > * + * {
        margin-top: 16px;
      }
    }

    &__loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  &__lottery {
    &__container {
      width: 100%;
      display: flex;
      justify-content: center;

      min-height: 100px;
    }

    &__content {
      width: 100%;
      padding: 1.5rem;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &__title {
      width: 100%;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      color: rgba($color-black, 0.85);
    }

    &__list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &__loading {
      width: 100%;
      padding: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}
