.superenalotto-section-addons {
  &__container {
    width: 100%;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $color-white;
    box-shadow: 0 1px 3px 0 rgba($color-black, 0.15),
      0 0 2px 0 rgba($color-black, 0.15);
  }

  &__loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  &__superstar {
    &__container {
      width: 100%;
      display: flex;
      align-items: center;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;
    }

    &__value {
      cursor: pointer;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;

      > * + * {
        margin-left: 12px;
      }
    }

    &__input {
      height: 1.25rem;
      width: 1.25rem;
      margin-top: 0;
    }

    &__label {
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.25rem;
      color: rgba($color-black, 0.55);
    }
  }

  @media screen and (max-width: $breakpoints-lg) {
    &__content {
      grid-template-columns: 1fr;
      grid-template-rows: 2fr;
      gap: 24px 0;
    }

    &__superstar {
      &__content {
        justify-content: center;
      }
    }
  }
}
