.primitiva-section-addons {
  &__container {
    width: 100%;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $color-white;
    box-shadow: 0 1px 3px 0 rgba($color-black, 0.15),
      0 0 2px 0 rgba($color-black, 0.15);
  }

  &__loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
  }

  &__refund {
    &__container {
      width: 100%;
      display: flex;
      align-items: center;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;

      > * + * {
        margin-left: 12px;
      }
    }

    &__title {
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.25rem;
      color: rgba($color-black, 0.55);
    }

    &__list {
      &__container {
        width: 100%;
        display: flex;
        align-items: center;
      }

      &__content {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-template-rows: 1fr;
        row-gap: 6px;
        column-gap: 6px;
      }

      &-item {
        width: 35px;
        height: 100%;
        padding: 0.5rem 0;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        justify-content: center;
        line-height: 14px;
        border-radius: 4px;
        border: 1px solid rgba($color-black, 0.2);
        color: rgba($color-black, 0.55);
        background-color: transparent;
        cursor: pointer;

        &:hover {
          font-weight: 600;
          color: $color-black;
          background-color: rgba($color-primitiva, 0.3);
          border: 1px solid rgba($color-primitiva, 0.3);
        }

        &.selected {
          font-weight: 600;
          color: $color-white;
          background-color: rgba($color-primitiva, 0.95);
          border: 1px solid rgba($color-primitiva, 0.95);
        }

        &.disabled {
          cursor: not-allowed;
          opacity: 0.7;
          background: rgba($color-grey, 0.1);

          &:hover {
            font-weight: 400;
            color: rgba($color-black, 0.55);
            background: rgba($color-grey, 0.1);
          }
        }
      }
    }
  }

  &__joker {
    &__container {
      width: 100%;
      display: flex;
      align-items: center;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__value {
      cursor: pointer;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;

      > * + * {
        margin-left: 12px;
      }
    }

    &__input {
      height: 1.25rem;
      width: 1.25rem;
      margin-top: 0;
    }

    &__label {
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.25rem;
      color: rgba($color-black, 0.55);
    }
  }

  @media screen and (max-width: $breakpoints-lg) {
    &__content {
      grid-template-columns: 1fr;
      grid-template-rows: 2fr;
      gap: 24px 0;
    }

    &__refund {
      &__content {
        flex-direction: column;

        > * + * {
          margin-top: 12px;
          margin-left: 0;
        }
      }

      &__list {
        &__container {
          align-items: center;
          justify-content: center;
        }
      }
    }

    &__joker {
      &__content {
        justify-content: center;
      }
    }
  }

  @media screen and (max-width: $breakpoints-sm) {
    &__refund {
      &__list {
        &__content {
          grid-template-columns: repeat(5, 1fr);
          grid-template-rows: repeat(2, 1fr);
        }
      }
    }
  }
}
