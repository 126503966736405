.superenalotto-section-summary {
  &__total {
    &__container {
      width: 100%;
      display: flex;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &__price {
      &__container {
        width: 100%;
        display: flex;
        margin-bottom: 1rem;
      }

      &__content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .value {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 24px;
          font-weight: 500;
          line-height: 1.75rem;
          color: rgba($color-black, 0.85);
        }
      }
    }

    &__draw-data {
      &__container {
        width: 100%;
        display: flex;
        margin-bottom: 0.5rem;
      }

      &__content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .value {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.25rem;
          color: rgba($color-black, 0.85);
        }
      }
    }

    &__date {
      &__container {
        width: 100%;
        display: flex;
      }

      &__content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .value {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.25rem;
          color: rgba($color-black, 0.45);
        }
      }
    }
  }

  &__buttons {
    &__container {
      width: 100%;
      display: flex;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .btn {
        padding: 0.5rem 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.25rem;
        text-align: center;
        border: none;
        border-radius: 1.125rem;
        transition: background-color 0.2s ease-out, color 0.2s ease-out,
          box-shadow 0.2s ease-out;
        opacity: 1;
        cursor: pointer;
        color: $color-white;
        background: $color-superenalotto;

        &.disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoints-lg) {
    &__total {
      &__price {
        &__content {
          justify-content: center;

          .value {
            justify-content: center;
          }
        }
      }

      &__draw-data {
        &__content {
          justify-content: center;

          .value {
            justify-content: center;
          }
        }
      }

      &__date {
        &__content {
          justify-content: center;

          .value {
            justify-content: center;
          }
        }
      }
    }

    &__buttons {
      &__content {
        justify-content: center;
      }
    }
  }
}
