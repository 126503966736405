.landing-footer {
  width: 100%;
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $color-white;
  box-shadow: 0px -1px 5px rgba($color-grey, 0.24);
}
