.breadcrumb {
  &__container {
    width: 100%;
    padding: 24px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    border: none;
    border-radius: 0.25rem;
    color: rgba($color-black, 0.85);
    background-color: $color-white;
    box-shadow: 0 1px 3px 0 rgba($color-black, 0.15),
      0 0 2px 0 rgba(rgba($color-black, 0.15), 0.15);
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 12px;
    }
  }

  &__loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      font-size: 18px;

      &.icon-home {
        margin-bottom: 2px;
      }
    }

    &.link {
      .label:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    > * + * {
      margin-left: 4px;
    }
  }
}
