.header-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-red;
  box-shadow: 0px 3px 10px -3px $color-grey;

  &__container {
    width: 100%;
    max-width: 1200px;
    padding: 0 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;

    .link {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-white;
      border-left: 1px solid #da4c4c;

      &:last-of-type {
        border-right: 1px solid #da4c4c;
      }

      &.active,
      &:hover {
        background-color: darken($color-red, 10%);
      }

      > * + * {
        margin-left: 8px;
      }
    }
  }
}
