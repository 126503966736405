h1.wp-block-heading {
  margin: 16px 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  color: $color-black;
}

h2.wp-block-heading {
  margin: 16px 0;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.2;
  color: $color-black;
}

h3.wp-block-heading {
  margin: 16px 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  color: $color-black;
}

h4.wp-block-heading {
  margin: 16px 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  color: $color-black;
}

h5.wp-block-heading {
  margin: 16px 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  color: $color-black;
  text-transform: uppercase;
}

h6.wp-block-heading {
  margin: 16px 0;
  font-size: 14px;
  color: $color-black;
  text-transform: uppercase;
}

p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: $color-grey;
}

p ~ p {
  margin: 4px 0;
}

ul {
  margin-left: 24px;

  li {
    list-style-type: disc;
  }
}

figure {
  &.wp-block-table {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    > table {
      width: 100%;
      border-collapse: collapse;
      font-size: 14px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

      thead tr {
        text-align: left;
        color: $color-white;
        background-color: #2f3b95;
      }

      th,
      td {
        padding: 12px 15px;
      }

      tbody tr {
        border-bottom: 1px solid #dddddd;
      }

      tbody tr:nth-of-type(even) {
        background-color: $color-white;
      }

      tbody tr:last-of-type {
        border-bottom: 2px solid #2f3b95;
      }
    }
  }

  &.wp-block-image {
    > img {
      width: 100%;
      height: auto;
    }
  }
}

details {
  padding: 10px;
  border-radius: 8px;
  border-bottom: 2px solid #f7f7f7;

  summary {
    cursor: pointer;
  }

  p {
    margin: 8px 12px;
  }
}

details ~ details {
  margin-top: 16px;
}
