.primitiva-header {
  width: 100%;
  display: flex;
  align-items: center;

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
