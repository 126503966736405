.megamillions-section-title {
  &__container {
    position: relative;
    width: 100%;
    padding: 24px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba($color-black, 0.15),
      0 0 2px 0 rgba($color-black, 0.15);
    background: $lg-megamillions;
  }

  &__loading {
    width: 100%;
    height: 110px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__content {
    width: 100%;
    height: 110px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &__header {
      width: 100%;
      display: flex;
      flex-direction: column;
      color: $color-white;

      .subtitle {
        text-transform: uppercase;
        font-weight: 800;
        font-size: 14px;
        line-height: 20px;
      }

      .title {
        text-transform: uppercase;
        font-weight: 800;
        font-size: 40px;
        line-height: 40px;
      }
    }

    &__body {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      color: $color-white;

      .subtitle {
        text-transform: uppercase;
        font-weight: 800;
        font-size: 14px;
        line-height: 20px;
      }

      .title-pot {
        text-transform: uppercase;

        .amount {
          font-weight: 800;
          font-size: 40px;
          line-height: 40px;
        }

        .unit {
          margin-left: 4px;
          font-weight: 600;
          font-size: 24px;
          line-height: 24px;
        }
      }
    }

    &__footer {
      padding: 0 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      color: $color-white;

      .subtitle {
        width: 100%;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 800;
        line-height: 20px;
        text-align: right;
        white-space: nowrap;
      }

      .title-countdown {
        width: 130px;
        font-size: 20px;
        font-weight: 800;
        line-height: 24px;
        text-align: right;
        white-space: nowrap;
      }

      .title-msg {
        width: 160px;
        font-size: 14px;
        text-transform: uppercase;
        text-align: end;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__container {
      margin: 0;
      padding: 12px;
    }

    &__content {
      width: 100%;
      height: auto;
      flex-direction: column;

      &__header {
        width: 100%;
        justify-content: center;
        align-items: center;

        .subtitle {
          width: 100%;
          font-size: 12px;
          text-align: center;
        }

        .title {
          width: 100%;
          font-size: 28px;
          text-align: center;
        }
      }

      &__body {
        width: 100%;
        justify-content: center;
        align-items: center;

        .subtitle {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          text-align: center;
        }

        .title-pot {
          width: 100%;
          font-size: 24px;
          text-align: center;
        }
      }

      &__footer {
        margin: 0;
        padding: 0;
        width: 100%;
        align-items: center;
        justify-content: center;

        .subtitle {
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .title-countdown {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        .title-msg {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
      }

      > * + * {
        margin-top: 16px;
      }
    }
  }
}
