.eurodreams-section-info {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: $color-white;
    box-shadow: 0 1px 3px 0 rgba($color-black, 0.15),
      0 0 2px 0 rgba($color-black, 0.15);
  }

  &__loading {
    width: 100%;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__content {
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    text-align: justify;
  }
}
