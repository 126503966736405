.bonoloto-body {
  width: 100%;
  min-height: calc(100vh - 150px - 124px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  &__container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 48px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.board {
        flex-direction: column;
      }

      &.info {
        margin-top: 48px;
        flex-direction: column;
      }
    }

    > * + * {
      margin-top: 96px;
    }
  }

  & > * + * {
    margin-top: 48px;
  }

  & > :last-child {
    margin-bottom: 128px;
  }

  @media screen and (max-width: 768px) {
    width: calc(100vw - 48px);
    min-height: calc(100vh - 64px - 140px);

    &__container {
      padding: 0;

      & > :first-child {
        margin-top: 32px;
      }
    }
  }
}

@import "./bonoloto-sections/index.scss";
