.gordo-section-summary__periodicity {
  &__container {
    width: 100%;
    display: flex;
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    &__container {
      width: 100%;
      display: flex;
      align-items: center;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  &__list {
    &__container {
      width: 100%;
      padding: 8px 0;
      display: flex;
      align-items: center;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;

      > * + * {
        margin-left: 16px;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &__label {
        margin-left: 4px;
      }
    }
  }

  @media screen and (max-width: $breakpoints-md) {
    &__list {
      &__content {
        text-align: center;
        justify-content: center;
      }
    }

    &__title {
      &__content {
        text-align: center;
        justify-content: center;
      }
    }
  }
}
