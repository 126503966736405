$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-report: unquote('"\\e900"');
$icon-report-alternate: unquote('"\\e901"');
$icon-help: unquote('"\\e902"');
$icon-help-alternate: unquote('"\\e903"');
$icon-info: unquote('"\\e904"');
$icon-info-alternate: unquote('"\\e905"');
$icon-delete-circled-outline: unquote('"\\e906"');
$icon-delete-circled-outline-alternate: unquote('"\\e907"');
$icon-close: unquote('"\\e927"');
$icon-twitter-x: unquote('"\\e914"');
$icon-twitter: unquote('"\\e93b"');
$icon-linkedin: unquote('"\\e94a"');
$icon-pinterest: unquote('"\\e909"');
$icon-instagram: unquote('"\\e94b"');
$icon-whatsapp: unquote('"\\e944"');
$icon-gmail: unquote('"\\e93c"');
$icon-facebook: unquote('"\\e939"');
$icon-youtube: unquote('"\\e93a"');
$icon-home: unquote('"\\e908"');
$icon-calendar: unquote('"\\e91b"');
$icon-folder: unquote('"\\e91c"');
$icon-drafts: unquote('"\\e91a"');
$icon-search: unquote('"\\e918"');
$icon-delete: unquote('"\\e915"');
$icon-dice: unquote('"\\e916"');
$icon-checkmark: unquote('"\\e917"');
$icon-menu: unquote('"\\e913"');
$icon-clock-filled: unquote('"\\e911"');
$icon-clock: unquote('"\\e912"');
$icon-user: unquote('"\\e90a"');
$icon-circle-user: unquote('"\\e90b"');
$icon-circle-user-alt: unquote('"\\e90c"');
$icon-prize: unquote('"\\e90d"');
$icon-people-star-alt: unquote('"\\e90e"');
$icon-people-star: unquote('"\\e90f"');
$icon-star: unquote('"\\e910"');
$icon-star-alt: unquote('"\\e919"');
$icon-shopping-cart: unquote('"\\e91d"');
$icon-log-in: unquote('"\\e91e"');
$icon-chevron-left: unquote('"\\e91f"');
$icon-chevron-right: unquote('"\\e920"');
$icon-chevron-up: unquote('"\\e921"');
$icon-chevron-down: unquote('"\\e922"');
$icon-arrow-up: unquote('"\\e923"');
$icon-arrow-down: unquote('"\\e924"');
$icon-arrow-left: unquote('"\\e925"');
$icon-arrow-right: unquote('"\\e926"');

