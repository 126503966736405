.euromillions-section-summary__num-draws {
  &__container {
    width: 100%;
    display: flex;
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    &__container {
      width: 100%;
      display: flex;
      align-items: center;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  &__list {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 0;

    &-item {
      padding: 0.5rem 1.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25rem;
      cursor: pointer;
      background: $color-white;
      color: $color-euromillions;
      background: $color-white;

      &:first-child {
        border: 1px solid $color-euromillions;
        border-right: none;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      & ~ & {
        border: 1px solid $color-euromillions;
        border-right: none;
        border-radius: 0;
      }

      &:last-child {
        border: 1px solid $color-euromillions;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &.selected {
        font-weight: 800;
        color: $color-white;
        background: $color-euromillions;
      }
    }
  }

  @media screen and (max-width: $breakpoints-lg) {
    &__title {
      &__content {
        justify-content: flex-end;
      }
    }

    &__list {
      justify-content: flex-end;
    }
  }

  @media screen and (max-width: $breakpoints-md) {
    &__title {
      &__content {
        text-align: center;
        justify-content: center;
      }
    }

    &__list {
      text-align: center;
      justify-content: center;
    }
  }
}
