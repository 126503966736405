.cookies-body {
  width: 100%;
  min-height: calc(100vh - 150px - 124px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  & > * + * {
    margin-top: 48px;
  }

  & > :last-child {
    margin-bottom: 128px;
  }

  &__title {
    margin: 48px 0px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    color: $color-black;
  }

  &__container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > * + * {
      margin-top: 96px;
    }
  }

  &__loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__info {
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    text-align: justify;
    background-color: $color-white;
    box-shadow: 0 1px 3px 0 rgba($color-black, 0.15),
      0 0 2px 0 rgba($color-black, 0.15);
  }

  @media screen and (max-width: 768px) {
    width: calc(100vw - 48px);
    min-height: calc(100vh - 64px - 140px);

    &__container {
      padding: 0;

      & > :first-child {
        margin-top: 32px;
      }
    }
  }
}
