.footer {
  &__container {
    width: 100%;
    min-height: 100px;
    max-width: 1200px;
    padding: 0 48px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    &__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0;
      font-weight: 500;

      .legal_age_img {
        width: 38px;
      }

      > * + * {
        margin-left: 8px;
      }

      .link {
        &:hover {
          cursor: pointer;
          text-underline-offset: 2px;
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      flex-direction: column;

      > * + * {
        margin-top: 16px;
      }
    }
  }
}
