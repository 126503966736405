.syndicate-card {
  &__container {
    display: grid;
    grid-template-areas:
      "a"
      "b"
      "c";
    max-width: 220px;
    min-width: 220px;
    border-radius: 15px;
    background: linear-gradient(rgb(233, 149, 55) 0%, rgb(241 211 55) 100%);
    cursor: pointer;
    box-shadow: 1px 1px 5px #b9b9b9;
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(1.04);
    }

    &.disabled {
      opacity: 0.45;
      cursor: not-allowed;

      &:hover {
        transform: none;
      }
    }

    > * + * {
      margin-top: 8px;
    }
  }

  &__header {
    width: 100%;
    grid-area: a;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: transparent;

    &__logo__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: transparent;

      .logo {
        width: 72px;
        height: 72px;
        margin-top: 12px;
        object-fit: contain;
      }
    }
  }

  &__body {
    width: 100%;
    grid-area: b;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: transparent;
    color: $color-white;

    &__name {
      &__container {
        .name {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }

    &__pot {
      &__container {
        width: 100%;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__content {
        .pot {
          .amount {
            font-size: 36px;
            font-weight: 600;
          }

          .unit {
            font-size: 24px;
            font-weight: 400;
          }

          > * + * {
            margin-left: 8px;
          }
        }

        .waiting {
          font-size: 18px;
        }
      }
    }

    &__countdown__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 600;

      > .icon {
        font-size: 24px;
        padding-bottom: 2px;
      }

      > * + * {
        margin-left: 8px;
      }
    }

    > * + * {
      margin-top: 8px;
    }
  }

  &__footer {
    width: 100%;
    grid-area: c;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: transparent;

    &__button__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: transparent;

      .button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 6px 12px;
        border-radius: 0 0 12px 12px;
        color: $color-white;
        font-size: 16px;
        font-weight: 600;
        background-color: $color-orange;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;

    &__container {
      grid-template-areas:
        "a b"
        "c c";
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: 30% 70%;
      height: 200px;
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      padding: 0;
    }

    &__header {
      &__logo__container {
        .logo {
          margin-top: 0;
        }
      }
    }

    &__body {
      margin-top: 16px;

      &__name {
        &__container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .name {
            font-size: 24cpx;
          }
        }
      }

      &__pot {
        &__container {
          height: auto;
          justify-content: flex-start;

          .amount {
            font-size: 32px;
          }
        }

        &__content {
          width: 100%;

          .pot {
            .amount {
              font-size: 32px;
            }
          }

          .waiting {
            font-size: 18px;
            font-weight: 600;
          }
        }
      }

      &__countdown__container {
        justify-content: flex-start;
      }
    }

    &__footer {
      margin-top: 8px;
      justify-content: flex-end;

      &__button__container {
        .button {
          padding: 8px 0;
        }
      }
    }
  }
}
