.primitiva-body-section {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@import "./title/index.scss";
@import "./board/index.scss";
@import "./addons/index.scss";
@import "./summary/index.scss";
@import "./info/index.scss";
