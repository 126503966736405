html,
body {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: $color-grey;
  scroll-behavior: smooth;
  cursor: default;
}

* {
  -moz-transition: all 0.2s, color 0s, font-size 0s;
  -webkit-transition: all 0.2s, color 0s, font-size 0s;
  -o-transition: all 0.2s, color 0s, font-size 0s;
  transition: all 0.2s, color 0s, font-size 0s;
}

a {
  color: $color-grey;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: $color-grey;
    text-decoration: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba($color-black, 0.3);
}

::-webkit-scrollbar-thumb {
  background: rgba($color-white, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba($color-black, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba($color-white, 0.3);
}

.grecaptcha-badge {
  z-index: 99998;
}
