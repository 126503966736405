.raffle-slide {
  width: 100%;
  height: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 12px;
  box-shadow: 1px 1px 5px #b9b9b9;
  background: radial-gradient(circle at 17% 50%, #57e4cd, #38bb9c 24%);

  &:hover {
    background: radial-gradient(
      circle at 17% 50%,
      darken(#57e4cd, 5%),
      darken(#38bb9c, 5%) 24%
    );
  }

  &.disabled {
    opacity: 0.4;
    filter: blur(1px) grayscale(1);
    pointer-events: none;
  }

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    > * + * {
      margin-left: 16px;
    }
  }

  &__header {
    &__container {
      width: 25%;
      height: 100%;
      padding: 12px 0 12px 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__body {
    &__container {
      width: 75%;
      height: 100%;
      padding: 12px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      flex-direction: column;
    }
  }

  &__footer {
    &__container {
      width: 100%;
      height: 100%;
      padding: 12px 24px 12px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__logo {
    &__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 72px;
        display: block;
        object-fit: cover;
      }
    }
  }

  &__pot {
    &__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .pot {
        color: $color-white;
        font-size: 20px;
        font-weight: bold;
        text-align: left;
        line-height: 1.43;
      }
    }
  }

  &__countdown {
    &__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .countdown {
        color: $color-white;
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        line-height: 1.43;
      }
    }
  }

  &__waiting {
    &__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .waiting {
        color: $color-white;
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        line-height: 1.43;
      }
    }
  }
}
