.primitiva {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: $bg-color;

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

// Header Component
@import "./primitiva-header/index.scss";

// Body Component
@import "./primitiva-body/index.scss";

// Footer Component
@import "./primitiva-footer/index.scss";
