.euromillions-section-summary {
  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba($color-black, 0.15),
      0 0 2px 0 rgba($color-black, 0.15);
  }

  &__loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__content {
    width: 100%;
    padding: 24px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: 1fr;

    .column {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;

      > * + * {
        margin-top: 12px;
      }
    }
  }

  @media screen and (max-width: $breakpoints-lg) {
    &__content {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, minmax(0, 1fr));

      .column {
        > * + * {
          margin-top: 0px;
        }
      }

      > * + * {
        margin-top: 24px;
      }
    }
  }

  @media screen and (max-width: $breakpoints-lg) {
    &__content {
      .column {
        > * + * {
          margin-top: 6px;
        }
      }

      .column:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media screen and (max-width: $breakpoints-md) {
    &__content {
      .column:first-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@import "./num-draws/index.scss";
@import "./total/index.scss";
