.blog-post-component {
  &__container {
    width: 100%;
    padding: 24px;
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;

    border: none;
    border-radius: 0.25rem;
    color: rgba($color-black, 0.85);
    background-color: $color-white;
    box-shadow: 0 1px 3px 0 rgba($color-black, 0.15),
      0 0 2px 0 rgba(rgba($color-black, 0.15), 0.15);
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
