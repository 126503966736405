.landing-body {
  width: 100%;
  min-height: calc(100vh - 150px - 124px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 48px;
    display: flex;
    align-items: center;
    flex-direction: column;

    & > :first-child {
      margin-top: 48px;
    }

    & > * + * {
      margin-top: 24px;
    }

    & > :last-child {
      margin-bottom: 128px;
    }
  }

  @media screen and (max-width: 768px) {
    width: calc(100vw - 48px);
    min-height: calc(100vh - 64px - 140px);

    &__container {
      padding: 0;

      & > :first-child {
        margin-top: 32px;
      }
    }
  }
}

@import "./body-slider-section/index.scss";

@import "./body-lottery-section/index.scss";

@import "./body-raffle-section/index.scss";
