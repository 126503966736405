.header-menu {
  &__toolbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: -4px;

      .icon-toolbar {
        color: $color-black;
        font-size: 24px;

        &.active {
          font-weight: 600;
        }
      }

      .icon-toolbar ~ .icon-toolbar {
        margin-left: 8px;
      }
    }
  }
}
