.body-raffle-section {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .raffle-title {
      &__container {
        width: 100%;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
          font-size: 24px;
          line-height: 26px;
          color: $color-black;
        }
      }
    }

    .loading-container {
      width: 100%;
      height: 135px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .raffle-cards {
      &__container {
        width: 100%;
        height: 135px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > * + * {
          margin-left: 24px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      height: auto;
      flex-direction: column;

      > * + * {
        margin-top: 16px;
      }

      .raffle-cards {
        &__container {
          height: auto;
        }

        &__content {
          flex-direction: column;
          justify-content: center;

          > * + * {
            margin-left: 0;
            margin-top: 24px;
          }
        }
      }
    }
  }
}

// Raffle Slide
@import "./raffle-slide/index.scss";
