.bonoloto-board {
  $self: &;

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__nav-tabs {
    &__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid rgba(235, 235, 235, 1);
    }
  }

  &__nav-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    font-weight: 400;
    padding: 8px 16px;
    cursor: pointer;

    &.active {
      color: $color-bonoloto;
      font-weight: 600;
      border-bottom: 2px solid $color-bonoloto;
    }
  }

  &__grid {
    &__container {
      width: 100%;
      padding: 16px 24px;
      border-bottom: 1px solid rgba(235, 235, 235, 1);

      &.fadeIn {
        animation: fadeIn 0.5s;
        -webkit-animation: fadeIn 0.5s;
        -moz-animation: fadeIn 0.5s;
        -o-animation: fadeIn 0.5s;
        -ms-animation: fadeIn 0.5s;
      }

      &.fadeOut {
        animation: fadeOut 0.5s;
        -webkit-animation: fadeOut 0.5s;
        -moz-animation: fadeOut 0.5s;
        -o-animation: fadeOut 0.5s;
        -ms-animation: fadeOut 0.5s;
      }

      &.hide {
        display: none;
      }
    }

    &__content {
      width: 100%;
      overflow-x: auto;

      &.single {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: 1fr;
        gap: 12px;
        padding-bottom: 1.5rem;
      }

      &.multiple {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-template-rows: 1fr;
        gap: 24px;
      }
    }
  }

  &__line {
    width: 240px;
    height: fit-content;
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(1, 1fr);

    border-radius: 8px;
    border: 1px solid rgba($color-black, 0.2);

    &.disabled {
      cursor: not-allowed;
      opacity: 0.7;
      background: rgba($color-grey, 0.1);
    }
  }

  &__grid {
    &__content.multiple {
      #{ $self }__line {
        width: 100%;
      }
    }
  }

  &__grid-numbers {
    &__container {
      width: 100%;
      padding: 12px;
      display: flex;
      flex-direction: column;
    }

    &__content {
      width: 100%;
      display: grid;
      grid-template-rows: repeat(2, auto);
      grid-template-columns: repeat(1, 1fr);
    }

    &__title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      text-transform: uppercase;
      background: transparent;

      &.hide {
        display: none;
      }
    }

    &__numbers {
      width: 100%;
      display: grid;
      grid-template-rows: repeat(10, 1fr);
      grid-template-columns: repeat(5, 1fr);
      gap: 8px;

      #{ $self }__number {
        width: 100%;
        height: 100%;
        padding: 0.5rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: rgba($color-black, 0.55);
        background-color: transparent;
        cursor: pointer;

        border: 1px solid rgba($color-black, 0.2);
        border-radius: 4px;

        &:hover {
          font-weight: 600;
          color: $color-black;
          background-color: rgba($color-bonoloto, 0.3);
          border: 1px solid rgba($color-bonoloto, 0.3);
        }

        &.selected {
          font-weight: 600;
          color: $color-white;
          background-color: rgba($color-bonoloto, 0.95);
          border: 1px solid rgba($color-bonoloto, 0.95);
        }

        &.disabled {
          cursor: not-allowed;
          opacity: 0.7;
          background: rgba($color-grey, 0.1);

          &:hover {
            font-weight: 400;
            color: rgba($color-black, 0.55);
            background: rgba($color-grey, 0.1);
          }
        }
      }
    }
  }

  &__actions {
    &__container {
      width: 100%;
      padding: 6px 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 24px;
      font-weight: 600;
      line-height: 25px;
      background: $color-bonoloto;

      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .icon {
        color: $color-white;

        &.icon-checkmark {
          display: none;

          &.show {
            display: inline-flex;
          }
        }

        &.clickable {
          cursor: pointer;
        }

        &.disabled {
          cursor: not-allowed;
          opacity: 0.7;
          background: rgba($color-grey, 0.1);

          &:hover {
            background: rgba($color-grey, 0.1);
          }
        }
      }
    }
  }

  &__config {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__header {
      width: 100%;
      display: flex;
      flex-direction: column;

      &__title {
        &-container {
          width: 100%;
          display: flex;
        }

        &-content {
          width: 100%;
          display: flex;
          font-size: 20px;
          font-weight: 500;
          line-height: 1.75rem;
          color: rgba($color-bonoloto, 0.85);
        }
      }

      &__subtitle {
        &-container {
          width: 100%;
          display: flex;
        }

        &-content {
          display: flex;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.25rem;
          color: $color-black;
        }
      }

      > * + * {
        margin-top: 1rem;
      }
    }

    &__body {
      width: 100%;
      display: flex;
      flex-direction: column;

      &__title {
        &-container {
          width: 100%;
          display: flex;
          margin-bottom: 0.5rem;
        }

        &-content {
          width: 100%;
          display: flex;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5rem;
          color: rgba($color-black, 0.85);
        }
      }

      &__combination {
        &-container {
          width: 100%;
          display: flex;
          flex-direction: column;
        }

        &-content {
          width: calc(100% - 1px);
          display: grid;
          gap: 16px;
          margin-bottom: 16px;

          &.numbers {
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-template-rows: repeat(2, 1fr);
          }
        }

        &-item {
          $item: &;
          width: 100%;
          height: 100%;
          padding: 1rem;
          display: flex;
          flex-direction: row;
          word-wrap: break-word;
          border: 1px solid transparent;
          border-radius: 0.25rem;
          cursor: pointer;
          background: $color-white;
          background-clip: border-box;
          box-shadow: 0 0 1px 0 rgba($color-black, 0.1),
            0 2px 12px 0 rgba($color-black, 0.1),
            0 1px 2px 0 rgba($color-black, 0.1);
          opacity: 0.9;
          transition: border 0.2s ease-out, opacity 0.2s ease-out;

          &__header {
            width: fit-content;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .number {
              font-size: 40px;
              font-weight: 800;
              line-height: 3rem;
              color: $color-bonoloto;
              max-width: 4rem;
              align-self: center;
            }
          }

          &__body {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .number {
              font-size: 16px;
              font-weight: 500;
              line-height: 1.5rem;
              color: rgba($color-black, 0.85);
              margin-bottom: 0;
            }

            .bets {
              font-weight: 14px;
              font-weight: 400;
              line-height: 1.25rem;
              color: rgba($color-black, 0.55);
            }
          }

          &__footer {
            width: fit-content;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
              font-size: 32px;
            }
          }

          &:hover {
            opacity: 1;
            border-color: $color-bonoloto;
            box-shadow: 0 4px 15px 0 rgba($color-black, 0.2);
          }

          &.selected {
            opacity: 1;
            border-color: $color-bonoloto;
            box-shadow: 0 4px 15px 0 rgba($color-black, 0.2);

            #{ $item }__body {
              .numbers,
              .bets {
                font-weight: 600;
                color: $color-bonoloto;
              }
            }

            #{ $item }__footer {
              .icon {
                color: $color-bonoloto;
              }
            }
          }
        }
      }
    }

    > * + * {
      margin-top: 2rem;
    }
  }

  @media screen and (max-width: $breakpoints-lg) {
    &__config {
      &__body {
        &__combination {
          &-content {
            &.numbers {
              grid-template-columns: repeat(2, minmax(0, 1fr));
              grid-template-rows: repeat(3, 1fr);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $breakpoints-md) {
    &__nav-tab {
      height: 45px;
    }

    &__grid {
      &__container {
        height: auto;
      }

      &__content {
        &.sigle {
          column-gap: 32px;
        }

        &.multiple {
          grid-template-columns: 1fr;
          grid-template-rows: auto;
          row-gap: 32px;
          column-gap: initial;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoints-sm) {
    &__nav-tabs {
      &__container {
        align-items: center;
        justify-content: center;
      }
    }

    &__config {
      &__body {
        &__combination {
          &-content {
            &.numbers {
              grid-template-columns: 1fr;
              grid-template-rows: repeat(6, 1fr);
            }
          }
        }
      }
    }
  }
}
