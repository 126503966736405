.blog {
  &-list {
    &__container {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: center;
    }

    &__content {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: center;
    }

    &__loading {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;

      border: none;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      color: rgba($color-black, 0.85);
      background-color: $color-white;
      box-shadow: 0 1px 3px 0 rgba($color-black, 0.15),
        0 0 2px 0 rgba($color-black, 0.15);
    }

    &__posts {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      .btn {
        padding: 0.5rem 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.25rem;
        text-align: center;
        border: 1px solid rgba($color-grey, 0.7);
        border-radius: 1.125rem;
        transition: background-color 0.2s ease-out, color 0.2s ease-out,
          box-shadow 0.2s ease-out;
        opacity: 1;
        cursor: pointer;
        color: $color-black;
        background: $color-white;

        &.disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }
      }

      > * + * {
        margin-top: 32px;
      }
    }

    &__empty {
      width: 100%;
      height: 80px;
      padding: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 0.25rem;
      color: rgba($color-black, 0.85);
      background-color: $color-white;
      box-shadow: 0 1px 3px 0 rgba($color-black, 0.15),
        0 0 2px 0 rgba($color-black, 0.15);

      .message {
        font-size: 16px;
        font-weight: 600;
      }
    }

    &__post {
      &__container {
        width: 100%;
        display: flex;
      }

      &__content {
        width: 100%;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: none;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        color: rgba(0, 0, 0, 0.85);
        background-color: #ffffff;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15),
          0 0 2px 0 rgba(0, 0, 0, 0.15);

        .row {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          > * + * {
            margin-left: 8px;
          }
        }

        > * + * {
          margin-top: 8px;
        }
      }

      &__title {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 2rem;
        text-transform: uppercase;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      &__date {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;

        > .icon {
          font-size: 14px;
          margin-right: 4px;
        }

        > .label {
          text-transform: capitalize;
        }
      }

      &__category {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;

        > .icon {
          font-size: 14px;
          margin-right: 4px;
        }

        > .label:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      &__excerpt {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
      }

      &__link {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}
