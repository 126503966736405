.search-input {
  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    border: 1px solid rgba($color-grey, 0.7);
    border-radius: 18px;
    background: rgba($color-white, 0.2);

    & > * + * {
      margin-left: 8px;
    }

    &.disabled {
      cursor: not-allowed;
    }

    &.hidden {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  &__icon {
    width: 16px;
    height: 16px;

    &.icon-delete-circled-outline {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__input {
    width: 100%;
    display: flex;
    align-items: center;
    border: none;
    background: transparent;

    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      justify-content: space-between;
      line-height: 21px;
    }
  }
}
