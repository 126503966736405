.body-slider-section {
  &__container {
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 5px #b9b9b9;
  }

  &__title {
    display: none;
  }

  @media screen and (max-width: 768px) {
    &__container {
      height: 250px;
    }
  }
}
