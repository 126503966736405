.header-buttons {
  width: 100%;
  display: flex;
  align-items: center;

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > * + * {
      margin-left: 16px;
    }
  }

  .header-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border: 1px solid $color-grey;
    padding: 4px 16px;
    border-radius: 6px;
    color: $color-black;

    .icon {
      font-size: 24px;
    }

    .label {
      font-size: 14px;

      &.ellipsis {
        max-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
      }
    }

    &.button-cart {
      border: none;
      padding: 0;
    }

    > * + * {
      margin-left: 4px;
    }
  }
}
