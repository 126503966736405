.body-lottery-section {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__content {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .lottery-title {
      &__container {
        width: 100%;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
          font-size: 24px;
          line-height: 26px;
          color: $color-black;
        }
      }
    }

    .loading-container {
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .lottery-cards {
      &__container {
        width: 100%;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .swiper {
          $self: &;

          &-horizontal {
            padding: 12px 0;

            #{ $self }-wrapper {
              background: transparent;

              #{ $self }-slide {
                background: transparent;
              }
            }
          }

          &-button {
            &-prev {
              pointer-events: auto !important;

              &::after {
                font-family: $icomoon-font-family;
                content: $icon-chevron-left;
                color: $color-black;
                background-color: $color-white;
                border-radius: 50%;
              }
            }

            &-next {
              pointer-events: auto !important;

              &::after {
                font-family: $icomoon-font-family;
                content: $icon-chevron-right;
                color: $color-black;
                background-color: $color-white;
                border-radius: 50%;
              }
            }
          }
        }

        > * + * {
          margin-left: 24px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      height: auto;
      flex-direction: column;

      > * + * {
        margin-top: 16px;
      }

      .lottery-cards {
        &__container {
          height: auto;
        }

        &__content {
          flex-direction: column;
          justify-content: center;

          > * + * {
            margin-left: 0;
            margin-top: 24px;
          }
        }
      }
    }
  }
}

// Lottery slide
@import "./lottery-slide/index.scss";
