.header-logo {
  width: 100%;
  display: flex;
  align-items: center;

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__content {
    min-height: 64px;
    width: 100%;
    display: flex;
    align-items: center;

    .link {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: $breakpoints-md) {
    &__img {
      width: 100px;
    }
  }
}
