// Primary Colors
$color-black: #000000;
$color-white: #ffffff;
$color-grey: #777e8b;
$color-red: #e91b11;

$bg-color: #f5f5f5;
$color-blue: #2f3b95;
$color-green: #25873f;
$color-orange: #d74b2c;

$color-euromillions: #3f7698;
$color-bonoloto: #a5bd3c;
$color-primitiva: #3bbb77;
$color-gordo: #f66167;
$color-eurodreams: #674a92;
$color-superenalotto: #a5bd3c;
$color-megamillions: #3f7698;
$color-powerball: #f66167;

$lg-euromillions: linear-gradient(to right, #3f7698, #043f65 100%);
$lg-bonoloto: linear-gradient(to right, #a5bd3c, #6b7b23 100%);
$lg-primitiva: linear-gradient(to right, #3bbb77, #097137 100%);
$lg-gordo: linear-gradient(to right, #f66167, #dc3f46 100%);
$lg-eurodreams: linear-gradient(to right, #674a92, #5d4088 100%);
$lg-superenalotto: linear-gradient(to right, #a5bd3c, #6b7b23 100%);
$lg-megamillions: linear-gradient(to right, #3f7698, #043f65 100%);
$lg-powerball: linear-gradient(to right, #f66167, #dc3f46 100%);

$color-quiniela: linear-gradient(to right, #fc3c3e, #be1315 100%);
$color-loteria-nat: linear-gradient(to right, #4e9bdc, #2d649a 100%);
$color-loteria-navidad: linear-gradient(to right, #ff624e, #f43219 100%);

// Pallete Colors
$main-color: $color-red;

// Text Colors
$main-text-color: $color-black;
$secondary-text-color: $color-white;
