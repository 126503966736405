.lottery-slide {
  width: 150px;
  height: 100%;
  min-height: 178px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 15px;
  box-shadow: 1px 1px 5px #b9b9b9;
  background: radial-gradient(circle at 50% 23%, #8ab5f7, #2f6cd0 54%);

  &:hover {
    transform: scale(1.05);
    background: radial-gradient(
      circle at 50% 23%,
      darken(#8ab5f7, 5%),
      darken(#2f6cd0, 5%) 54%
    );
  }

  &.disabled {
    opacity: 0.4;
    filter: blur(1px) grayscale(1);
    pointer-events: none;
  }

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__header {
    width: 100%;
    min-height: 60px;
    padding: 12px 12px 0 12px;

    &__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__body {
    width: 100%;
    height: 100%;
    min-height: 72px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__footer {
    width: 100%;
    padding: 8px 6px;
    background-color: darken(#2f6cd0, 10%);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__logo {
    &__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 72px;
        display: block;
        object-fit: cover;
      }
    }
  }

  &__pot {
    &__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: baseline;
      justify-content: center;
      flex-wrap: wrap;
      color: $color-white;

      .currency {
        flex-grow: 1;
        font-size: 16px;
        font-weight: 600;
        text-align: right;
      }

      .amount {
        margin-left: 4px;
        flex-grow: 1;
        font-size: 24px;
        font-weight: 700;
        text-align: left;
      }

      .text,
      .waiting {
        flex-basis: 100%;
        flex-grow: 1;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
      }
    }
  }

  &__button {
    &__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: 600;
      font-size: 14px;
      color: $color-white;
    }
  }

  &.euromillions-slide,
  &.primitiva-slide {
    .lottery-slide__header {
      .lottery-slide__logo {
        img {
          width: 100px;
        }
      }
    }
  }

  &.gordo-slide {
    .lottery-slide__header {
      .lottery-slide__logo {
        img {
          width: 120px;
        }
      }
    }

    .lottery-slide__body {
      min-height: 80px;
    }
  }

  &.quiniela-slide,
  &.powerball-slide,
  &.megamillions-slide,
  &.superenalotto-slide {
    .lottery-slide__header {
      .lottery-slide__logo {
        img {
          width: 58px;
        }
      }
    }
  }
}
